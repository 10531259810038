import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import api from "../../../services/api";
import { Grid, Paper, Text } from "../../core";
import { SeccionHeader, ConfirmDialog, AlertDialog, LineDetails } from "../../patterns";

export default function LessonGroupDetails() {
  const { setLoading } = useContext(AppContext);

  const [lessonGroup, setLessonGroup] = useState({
    title_pt: "",
    title_en: "",
    title_es: "",
    description_pt: "",
    description_en: "",
    description_es: "",
    active: false,
    imgThumb: null,
    slug: "",
    metaTitle: "",
    metaDescription: "",
    lessons: [],
  });
  const [state, setState] = useState({
    showConfirmDialog: false,
  });

  const { id } = useParams();
  const history = useHistory();

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });

  useEffect(() => {
    setLoading(true);
    api
      .get(`/admin/lesson-groups/${id}`)
      .then((res) => {
        setLoading(false);
        if (res.data?.lessonGroup) {
          setLessonGroup(res.data.lessonGroup);
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ 
          errors: err.response?.data?.errors || [{ message: "Erro ao carregar grupo de lições" }], 
          show: true 
        });
        history.push("/app/lesson-groups");
      });
  }, [id, setLoading, history]);

  const handleEditButton = (id) => {
    if (id) {
      history.push(`/app/lesson-groups/edit/${id}`);
    }
  };

  const handleDeleteButton = () => {
    if (lessonGroup?.id) {
      api
        .delete(`/admin/lesson-groups/${lessonGroup.id}`)
        .then(() => {
          history.push(`/app/lesson-groups`);
        })
        .catch((err) => {
          setAlert({ 
            errors: err.response?.data?.errors || [{ message: "Erro ao excluir grupo de lições" }], 
            show: true 
          });
        });
    }
  };

  return (
    <>
      <Grid container>
        <SeccionHeader
          title={lessonGroup?.title_pt || "Grupo de Lições"}
          editButton
          editButtonAction={() => handleEditButton(lessonGroup?.id)}
          deleteButton
          deleteButtonAction={() => setState({ ...state, showConfirmDialog: true })}
        />
        <AlertDialog
          open={alert.show}
          handleClose={() => setAlert({ ...alert, show: false })}
          errors={alert.errors}
        />
        <Grid item xs={12}>
          <Paper style={{ flex: 1, display: "flex", padding: 20 }}>
            <Grid container>
              <Grid item xs={12}>
                <Text variant="h6">Dados do Grupo de Lições</Text>
              </Grid>
              <LineDetails title="Título (PT)" text={lessonGroup?.title_pt || ""} />
              <LineDetails title="Título (EN)" text={lessonGroup?.title_en || ""} />
              <LineDetails title="Título (ES)" text={lessonGroup?.title_es || ""} />
              <LineDetails title="Descrição (PT)" text={lessonGroup?.description_pt || ""} />
              <LineDetails title="Descrição (EN)" text={lessonGroup?.description_en || ""} />
              <LineDetails title="Descrição (ES)" text={lessonGroup?.description_es || ""} />
              <LineDetails title="Slug" text={lessonGroup?.slug || ""} />
              <LineDetails title="Meta Title" text={lessonGroup?.metaTitle || ""} />
              <LineDetails title="Meta Description" text={lessonGroup?.metaDescription || ""} />
              <LineDetails title="Status" text={lessonGroup?.active ? "Ativo" : "Inativo"} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {state.showConfirmDialog && (
        <ConfirmDialog
          open={state.showConfirmDialog}
          title="Grupos de Lições"
          question={`Deseja realmente excluir o grupo '${lessonGroup?.title_pt || ""}'?`}
          handleConfirm={handleDeleteButton}
          handleClose={() => setState({ ...state, showConfirmDialog: false })}
          cancelButton
        />
      )}
    </>
  );
} 