import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import {
  Paper,
  IconButton,
  Text,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Select,
  MenuItem,
  Div,
  Spacer,
  TextField,
  Divider
} from '../../core';
import { SecctionContainer, SeccionHeader, AlertDialog } from '../../patterns';
import { EditIcon, ViewListIcon } from '../../core/Icons';
import { AppContext } from '../../../contexts/AppContext';
import { LessonPaginator } from '../../patterns/lesson/LessonPaginator';
import { getResizedImageUrl } from '../../../helpers/image';

export default function LessonGroups() {
  const { setLoading } = useContext(AppContext);
  const [lessonGroups, setLessonGroups] = useState([]);
  const [query, setQuery] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    count: 0,
    sort: 'asc',
    status: '',
    sortField: 'order'
  });

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: 'Ocorreu um erro' }]
  });

  useEffect(() => {
    let localPagination = window.localStorage.getItem('lessonGroupsPagination');

    if (localPagination) {
      localPagination = JSON.parse(localPagination);
      setPagination(localPagination);
      loadLessonGroups({ localPagination });
    }

    if (!localPagination) {
      loadLessonGroups({ localPagination: pagination });
    }

    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      window.localStorage.setItem('lessonGroupsPagination', JSON.stringify(pagination));
      loadLessonGroups({ localPagination: pagination });
    }
  }, [pagination.page, pagination.per_page, pagination.sort, pagination.status]);

  const loadLessonGroups = useCallback(({ localPagination = pagination }) => {
    setLoading(true);

    const statusParam = localPagination.status ? `&active=${localPagination.status === 'true'}` : '';
    const queryParam = query ? `&q=title:like:${query}` : '';

    api.get(`/admin/lesson-groups?page=${localPagination.page}&per_page=${localPagination.per_page}&sort=${localPagination.sortField}:${localPagination.sort}${queryParam}${statusParam}`)
      .then(res => {
        setPagination(prevPagination => ({ 
          ...prevPagination, 
          count: res.data.pagination.count 
        }));
        setLoading(false);
        setLessonGroups(res.data.lessongroups || []);
      })
      .catch(err => {
        setLoading(false);
        setLessonGroups([]);
        setAlert({ 
          errors: err.response?.data?.errors || [{ message: "Erro ao carregar grupos de lições" }], 
          show: true 
        });
      });
  }, [setLoading, query]);

  const history = useHistory();

  const handleEdit = useCallback((id) => history.push(`/app/lesson-groups/edit/${id}`), [history]);
  const handleNewLessonGroup = useCallback(() => history.push('/app/lesson-groups/edit/new'), [history]);
  const handleLessonGroupDetails = useCallback((id) => history.push(`/app/lesson-groups/${id}`), [history]);

  const onPerPageChange = ({ target }) => {
    setPagination({ ...pagination, per_page: target.value, page: 1 });
  };

  const onPageChange = (event, newPage) => {
    setPagination({ ...pagination, page: newPage + 1 });
  };

  const handleSortChange = ({ target }) => {
    setPagination({
      ...pagination,
      sort: target.value
    });
  };

  const handleStatusChange = ({ target }) => {
    setPagination({
      ...pagination,
      status: target.value
    });
  };

  const handleQueryChange = ({ target }) => {
    const { value } = target;
    setQuery(value);
  };

  return (
    <SecctionContainer>
      <SeccionHeader title="Grupos de Lições" newButtonAction={handleNewLessonGroup} newButton />
      <AlertDialog
        open={alert.show}
        handleClose={() => setAlert({ ...alert, show: false })}
        errors={alert.errors}
      />
      <TableContainer component={Paper}>
        <Div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <TextField
            label="Busca"
            variant="outlined"
            refs="queryLessonGroup"
            value={query}
            fullWidth
            autoFocus
            name="queryLessonGroup"
            placeholder="Digite o termo que deseja buscar"
            onChange={handleQueryChange}
            onKeyPress={({ key }) => {
              if (key === 'Enter') loadLessonGroups({ localPagination: pagination });
            }}
            style={{ marginTop: 20, marginLeft: 20 }}
          />
          <Spacer />
          <Select
            id="sort"
            value={pagination.sort}
            handleChange={handleSortChange}
            label="Ordem"
            name="sort"
            style={{ marginLeft: 20, marginRight: 20, maxWidth: 200 }}
          >
            <MenuItem value="asc">Ordem (crescente)</MenuItem>
            <MenuItem value="desc">Ordem (decrescente)</MenuItem>
          </Select>
          <Spacer />
          <Select
            id="status"
            value={pagination.status || ''}
            handleChange={handleStatusChange}
            label="Status"
            name="status"
            style={{ marginRight: 20, maxWidth: 200 }}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="true">Ativo</MenuItem>
            <MenuItem value="false">Inativo</MenuItem>
          </Select>
        </Div>
        <LessonPaginator
          pagination={pagination}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
        <Divider />
        <Table size="medium">
          <TableBody>
            {lessonGroups.map((group) => (
              <TableRow key={group.id} hover>
                <TableCell
                  width={280}
                  onClick={() => handleLessonGroupDetails(group.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ backgroundColor: 'gray', width: 120, height: 68 }}>
                      <img
                        alt={group.title}
                        src={getResizedImageUrl({ url: group.imgThumb, width: 120, height: 68 })}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: 10
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: 700,
                          maxWidth: 160,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {group.title}
                      </Text>
                      <Text
                        style={{
                          color: '#666',
                          fontSize: '0.875rem'
                        }}
                      >
                        {group.lessons?.length || 0} lições
                      </Text>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={() => handleEdit(group.id)}
                    title="Editar grupo"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SecctionContainer>
  );
}
