import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import api from "../../../services/api";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  List,
  ListItem,
  Button
} from "../../core";
import { AlertDialog } from "../../patterns";

export const LessonSelectDialog = ({ handleSelect, handleClose }) => {
  const { setLoading } = useContext(AppContext);
  const [lessons, setLessons] = useState([]);
  const [query, setQuery] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    count: 0,
    pages: 0,
    sort: 'asc',
    sortField: 'number'
  });
  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });

  useEffect(() => {
    loadLessons(pagination);
  }, [pagination.page]);

  const loadLessons = (pagination) => {
    setLoading(true);
    api
      .get(
        `/admin/lessons?fields=title,number,&per_page=${pagination.per_page}}&sort=${pagination.sortField}:${pagination.sort}&page=${pagination.page}&q=youtubeChannel:eq:Fundamentos,title:like:${query}||number:eq:${query}`)
      .then((response) => {
        setLoading(false);
        const { lessons, pagination: paginationData } = response.data;
        setLessons(lessons);
        setPagination({ ...pagination, pages: paginationData.pages })
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ errors: err.response, show: true });
      });
  };

  const handleQueryChange = ({ target }) => {
    const { value } = target;
    setQuery(value);
  };

  const onPageChange = (newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  return (
    <Dialog 
      open={true}
      onClose={handleClose}
      aria-describedby="lesson-select-dialog"
      disablePortal
      disableScrollLock
      disableEnforceFocus
    >
      <AlertDialog
        open={alert.show}
        handleClose={() => setAlert({ ...alert, show: false })}
        errors={alert.errors}
      />
      <DialogTitle>
        <TextField
          label="Busca"
          variant="outlined"
          refs="queryLesson"
          value={query}
          fullWidth
          autoFocus
          name="queryLesson"
          placeholder="Digite o termo que deseja buscar"
          onChange={handleQueryChange}
          onKeyPress={({ key }) => { if (key === "Enter") loadLessons({ ...pagination, page: 1 }) }}
        />
      </DialogTitle>
      <DialogContent style={{ minWidth: 450 }}>
        <List>
          {lessons.map((lesson) => (
            <ListItem
              onClick={() => handleSelect(lesson)}
              button
              key={lesson?.id}
            >
              {lesson?.number} - {lesson?.title}
            </ListItem>
          ))}
        </List>
        <DialogActions>
          <Button
            style={{ left: 0 }}
            color="secondary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={pagination.page - 1 === 0}
            onClick={() => onPageChange(pagination.page - 1)}
          >
            Anterior
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={pagination.page + 1 > pagination.pages}
            onClick={() => onPageChange(pagination.page + 1)}
          >
            Próximo
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
