import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import api from "../../../services/api";
import {
  Grid,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  Text,
  InnerLoading,
  Image,
  Divider,
  List,
  ListItem,
  IconButton,
} from "../../core";
import {
  SeccionHeader,
  ConfirmDialog,
  Upload,
  AlertDialog,
} from "../../patterns";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Delete as DeleteIcon,
  DragIndicator as DragHandleIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import { LessonSelectDialog } from "../../patterns/lesson/LessonSelectDialog";

export default function LessonGroupEdit() {
  const { setLoading } = useContext(AppContext);
  const { id } = useParams();
  const history = useHistory();

  const [lessongroup, setLessonGroup] = useState({
    title_pt: "",
    title_en: "",
    title_es: "",
    description_pt: "",
    description_en: "",
    description_es: "",
    active: false,
    imgThumb: null,
    slug: "",
    metaTitle: "",
    metaDescription: "",
    lessons: [],
  });

  const [state, setState] = useState({
    title: id === "new" ? "Novo Grupo de Lições" : "Grupo de Lições",
    showConfirmDialog: false,
    errors: {},
    imgFileLoading: false,
    showLessonSelectDialog: false,
  });

  const [alert, setAlert] = useState({
    show: false,
    errors: [{ message: "Ocorreu um erro" }],
  });

  useEffect(() => {
    if (id !== "new") {
      setLoading(true);
      api
        .get(`/admin/lesson-groups/${id}`)
        .then((res) => {
          setLoading(false);
          setLessonGroup(res.data.lessongroup);
          setState((prevState) => ({
            ...prevState,
            title: res.data.lessongroup.title_pt,
          }));
        })
        .catch((err) => {
          setAlert({
            errors: err.response?.data?.errors || [
              { message: "Erro ao carregar grupo de lições" },
            ],
            show: true,
          });
          setLoading(false);
          history.push("/app/lesson-groups");
        });
    }
  }, [id, setLoading, history]);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setLessonGroup((prevGroup) => ({
      ...prevGroup,
      [name]: value,
    }));

    setState((prevState) => {
      const newErrors = { ...prevState.errors };
      delete newErrors[name];
      return {
        ...prevState,
        errors: newErrors,
      };
    });
  };

  const handleDeleteButton = () => {
    api
      .delete(`/admin/lesson-groups/${lessongroup.id}`)
      .then(() => {
        history.push(`/app/lesson-groups`);
      })
      .catch((err) => {
        setAlert({
          errors: err.response?.data?.errors || [
            { message: "Erro ao excluir grupo de lições" },
          ],
          show: true,
        });
      });
  };

  const handleCancelButton = () => {
    if (lessongroup.hasOwnProperty("id"))
      history.push(`/app/lesson-groups/${lessongroup.id}`);
    else history.push("/app/lesson-groups");
  };

  const validateFields = () => {
    const errors = {};

    if (!lessongroup.title_pt?.trim()) {
      errors.title = "Título em português é obrigatório";
    }

    if (!lessongroup.slug?.trim()) {
      errors.slug = "Slug é obrigatório";
    }

    return errors;
  };

  const handleSaveButton = () => {
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      setState((prevState) => ({
        ...prevState,
        errors: validationErrors,
      }));
      setAlert({
        show: true,
        errors: [
          { message: "Por favor, preencha todos os campos obrigatórios" },
        ],
      });
      return;
    }

    let request;
    const payload = {
      lessongroup: {
        ...lessongroup,
        title_pt: lessongroup.title_pt?.trim(),
        title_en: lessongroup.title_en?.trim(),
        title_es: lessongroup.title_es?.trim(),
        description_pt: lessongroup.description_pt?.trim(),
        description_en: lessongroup.description_en?.trim(),
        description_es: lessongroup.description_es?.trim(),
        slug: lessongroup.slug?.trim(),
        metaTitle: lessongroup.metaTitle?.trim(),
        metaDescription: lessongroup.metaDescription?.trim(),
        lessons: lessongroup.lessons.map((lesson) => {
          return lesson.id;
        }),
      },
    };

    if (lessongroup.hasOwnProperty("id")) {
      request = api.patch(`/admin/lesson-groups/${lessongroup.id}`, payload);
    } else {
      request = api.post(`/admin/lesson-groups`, payload);
    }

    setLoading(true);
    request
      .then((response) => {
        console.log("Resposta da API:", response.data);
        setLoading(false);
        history.push(`/app/lesson-groups`);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Erro completo:", err);
        console.error("Resposta do erro:", err.response?.data);

        const errors = {};
        if (err.response?.data?.errors) {
          err.response.data.errors.forEach((error) => {
            console.error("Erro específico:", error);
            if (error.field) {
              const field = error.field.split(".")[1] || error.field;
              errors[field] = error.message;
            }
          });
        }

        setAlert({
          errors: err.response?.data?.errors || [
            {
              message:
                err.response?.data?.message || "Erro ao salvar grupo de lições",
            },
          ],
          show: true,
        });

        setState((prevState) => ({
          ...prevState,
          errors: Object.keys(errors).length > 0 ? errors : prevState.errors,
        }));
      });
  };

  const handleUploadFile = (event) => {
    const { name, loadedFile } = event;
    if (loadedFile[0]) {
      setLoading(true);
      const dataFormFile = new FormData();
      dataFormFile.append("file", loadedFile[0]);

      api
        .post(`/upload`, dataFormFile)
        .then((res) => {
          setLoading(false);
          const { url } = res.data;

          setLessonGroup((prevGroup) => ({
            ...prevGroup,
            [name]: url,
          }));

          setState((prevState) => ({
            ...prevState,
            imgFileLoading: false,
          }));
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            errors: err.response?.data?.errors || [
              { message: "Erro ao fazer upload do arquivo" },
            ],
            show: true,
          });
        });
    }
  };

  const handleAddLesson = (lesson) => {
    setState((prev) => ({
      ...prev,
      showLessonSelectDialog: false,
    }));
    setLessonGroup((prev) => ({
      ...prev,
      lessons: [...prev.lessons, lesson],
    }));
  };

  const handleRemoveLesson = (lessonId) => {
    setLessonGroup((prev) => ({
      ...prev,
      lessons: prev.lessons.filter((l) => l.id !== lessonId),
    }));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(lessongroup.lessons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLessonGroup((prev) => ({
      ...prev,
      lessons: items,
    }));
  };

  return (
    <>
      <Grid container>
        <SeccionHeader
          title={state.title}
          deleteButtonAction={() =>
            setState({ ...state, showConfirmDialog: true })
          }
          deleteButton={lessongroup.hasOwnProperty("id")}
        />
        <AlertDialog
          open={alert.show}
          handleClose={() => setAlert({ ...alert, show: false })}
          errors={alert.errors}
        />

        <Grid item xs={12}>
          <Paper
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Text variant="h6">Títulos</Text>
                    <TextField
                      label="Título em português"
                      variant="outlined"
                      refs="title_pt"
                      value={lessongroup.title_pt || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_pt"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      label="Título em inglês"
                      variant="outlined"
                      refs="title_en"
                      value={lessongroup.title_en || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_en"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      label="Título em espanhol"
                      variant="outlined"
                      refs="title_es"
                      value={lessongroup.title_es || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("title")}
                      helperText={state.errors.title}
                      name="title_es"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <Text variant="h6">Descrições</Text>
                    <TextField
                      label="Descrição em português"
                      variant="outlined"
                      refs="description_pt"
                      value={lessongroup.description_pt || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_pt"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <TextField
                      label="Descrição em inglês"
                      variant="outlined"
                      refs="description_en"
                      value={lessongroup.description_en || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_en"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <TextField
                      label="Descrição em espanhol"
                      variant="outlined"
                      refs="description_es"
                      value={lessongroup.description_es || ""}
                      fullWidth
                      error={state.errors.hasOwnProperty("description")}
                      helperText={state.errors.description}
                      name="description_es"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                    <Text variant="h6" style={{ marginTop: 20 }}>
                      SEO
                    </Text>
                    <TextField
                      label="SLUG"
                      variant="outlined"
                      refs="slug"
                      value={lessongroup.slug || ""}
                      fullWidth
                      helperText={state.errors.slug}
                      name="slug"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      label="Meta Title"
                      variant="outlined"
                      refs="metaTitle"
                      value={lessongroup.metaTitle || ""}
                      fullWidth
                      helperText={state.errors.metaTitle}
                      name="metaTitle"
                      onChange={handleChange}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      label="Meta Description"
                      variant="outlined"
                      refs="metaDescription"
                      value={lessongroup.metaDescription || ""}
                      fullWidth
                      helperText={state.errors.metaDescription}
                      name="metaDescription"
                      onChange={handleChange}
                      multiline
                      style={{ marginBottom: 20 }}
                      inputProps={{ style: { lineHeight: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      {!lessongroup.imgThumb && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Imagem Thumb
                          </Text>
                          {state.imgFileLoading && <InnerLoading />}
                          <Upload
                            acceptedFiles={["image/*"]}
                            dropzoneText={
                              "Arraste e solte a imagem ou clique aqui"
                            }
                            onChange={(loadedFile) =>
                              handleUploadFile({ name: "imgThumb", loadedFile })
                            }
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                            onDrop={() =>
                              setState({ ...state, imgFileLoading: true })
                            }
                          />
                        </div>
                      )}
                      {lessongroup.imgThumb && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginBottom: 20,
                          }}
                        >
                          <Text
                            style={{ fontWeight: "bold", marginBottom: 10 }}
                          >
                            Imagem Thumb
                          </Text>
                          <Image
                            src={lessongroup.imgThumb}
                            style={{ width: "100%" }}
                          />
                          <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              setLessonGroup({ ...lessongroup, imgThumb: null })
                            }
                          >
                            Remover
                          </Button>
                        </div>
                      )}
                    </div>
                    <Select
                      label="Ativo no aplicativo"
                      id="active"
                      fullWidth
                      name="active"
                      handleChange={handleChange}
                      value={lessongroup.active}
                    >
                      <MenuItem value={true}>Ativo</MenuItem>
                      <MenuItem value={false}>Inativo</MenuItem>
                    </Select>
                    <Divider style={{ margin: "20px 0" }} />
                    <Text variant="h6">Lições Vinculadas</Text>

                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          showLessonSelectDialog: true,
                        }))
                      }
                      style={{ marginBottom: 20 }}
                    >
                      Adicionar Lição
                    </Button>

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="lessons">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <List>
                              {lessongroup.lessons.map((lesson, index) => (
                                <Draggable
                                  key={lesson.id}
                                  draggableId={lesson.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "10px",
                                        marginBottom: "5px",
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton {...provided.dragHandleProps}>
                                          <DragHandleIcon />
                                        </IconButton>
                                        <Text>{`${lesson.number} - ${lesson.title}`}</Text>
                                      </div>
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveLesson(lesson.id)
                                        }
                                        color="secondary"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItem>
                                  )}
                                </Draggable>
                              ))}
                            </List>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                color="primary"
                onClick={handleCancelButton}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveButton}
              >
                Salvar
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {state.showConfirmDialog && (
        <ConfirmDialog
          open={state.showConfirmDialog}
          title="Grupos de Lições"
          question={`Deseja realmente excluir o grupo '${lessongroup.title_pt}'?`}
          handleConfirm={handleDeleteButton}
          handleClose={() => setState({ ...state, showConfirmDialog: false })}
          cancelButton
        />
      )}
      {state.showLessonSelectDialog && (
        <LessonSelectDialog
          handleSelect={handleAddLesson}
          handleClose={() =>
            setState((prev) => ({ ...prev, showLessonSelectDialog: false }))
          }
        />
      )}
    </>
  );
}
