import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#557316',
            contrastText: '#FFF'
        },
        secondary: {
            main: '#748a45',
            contrastText: '#fff'
        },
        primaryES: {
            main: '#748a45',
            contrastText: '#000'
        }
    },

    typography: {
        fontWeightRegular: 500,
        fontSize: 14,
        fontFamily: ["Montserrat"].join(','),
        h5: {
            fontWeight: 700,
            fontSize: 22,
            marginBottom: 10,
            marginTop: 10
        },
        h6: {
            fontWeight: 700,
            paddingBottom: 20
        },
        body1: {
            fontSize: 13
        }
    },
    overrides: {
        MuiDropzoneArea: {
            textContainer: {
                backgroundColor: "#FFF",
                padding: 10,
                margin: 0,
                
            },
            text: {
                fontSize: 14,
                fontWeight: 300
            },
            root: {
                minHeight: 0
            }
        }
    }
}
);

export default theme
